import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Img from "gatsby-image/withIEPolyfill"
// import Button from "react-bootstrap/Button"
import { Link } from "gatsby"
import curve from "../images/landing-page/curve-white-bg.svg"
import Thumbnail from "../components/book/thumbnail"
import CardDeck from "react-bootstrap/CardDeck"

import "../styles/main.scss"

export default function Home({ data }) {
  console.log(data)
  return (
    <>
      <Container fluid className="header p-0 d-flex flex-column align-items-center">
        <header className="title m-auto">
          <p className="author">Johan Cornelissen</p>
          <p className="tilde">&tilde;</p>
          <h1>De aarde is onder mijn voeten</h1>
          <p className="subtitle">Atlas van een creatief proces</p>
        </header>
        <div className="call-to-action text-center">
          <Link to="/book/p8" className="btn btn-lg btn-primary shadow ml-2 mr-2 mb-3">
            Extra online materiaal ›
          </Link>

          <Link to="#more" 
            className="btn btn-lg btn-primary-outline shadow border-1 ml-2 mr-2 mb-3 text-light">
            Meer over het boek ›
          </Link>
        </div>
        <div className="curve">
          <img src={curve} />
        </div>
        <div className="background">
          <Img fluid={data.background.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            style={{height: "100%", width: "100%", minHeight: 700}} />
        </div>
      </Container>

      <Container id="more">
        <Row className="py-5">
          <Col md={{span: 5}} className="pb-3">
            <Img fluid={data.book.childImageSharp.fluid}
              style={{maxWidth: 300}} />
          </Col>
          <Col md={{span: 7}} className="mt-3">
            <p className="lead">
              Wat ziet de kunstenaar die reist? <br />
              Wanneer wordt de reis kunst? <br />
              Hoe werkt de kunst van het creatief proces?
            </p>
            <p className="lead">
              In <em>De aarde is onder mijn voeten</em> beschrijft de auteur 
              Johan Cornelissen hoe hij als kunstenaar zijn horizon verbreedde 
              door te reizen. In zijn reis over de evenaar vergelijkt hij de 
              werkelijkheid met de abstractie van de lijn. Terug in Nederland 
              onderzoekt en doceert hij wetmatigheden van creatieve processen.
            </p>

            <p className="text-primary">
              Johan Cornelissen is trainer op het gebied van persoonlijke ontwikkeling 
              bij o.a. het programma “Ik en de Anderen” dat Bureau Bout en 
              Partners uitvoert in samenwerking met de Baak, Management 
              Centrum VNO-NCW.
            </p>
            <p>
              <em>De aarde is onder mijn voeten</em> is uitgegen als hardcover
              gebonden boek en is te bestellen via uitgeverij Stili Novi.
            </p>
            <Link to="https://stilinovi.nl/shop/de-aarde-is-onder-mijn-voeten/"
              className="btn btn-primary btn-lg">
              Bestel het boek
            </Link>
          </Col>
        </Row>

        <Row className="pt-5">
          <Col md={7}>
              <h3>Extra online materiaal</h3>
              <p>
                Alle werken die in het boek zijn opgenomen zijn{' '} 
                <Link to="/boek/p8">op deze website</Link>{' '} 
                in kleur te bekijken. Daarnaast zijn er extra fotoseries, 
                geluidswerken en artikelen opgenomen. De reis is bovendien te volgen
                aan de hand van satellietbeelden van plaatsen die in het boek 
                worden bezocht. Hieronder twee voorbeelden.
              </p>
          </Col>
        </Row>
        <Row className="pb-5">
          <Col>
            <CardDeck className="justify-content-center py-4">
              {data.page.objects.map(
                obj => <Thumbnail object={obj} key={obj.object_id} />
              )}
            </CardDeck>
          </Col>
        </Row>
        <Row className="py-5">
          <Col>
              <h3>Johan Cornelissen: Een genre in de geografische kunst</h3>
              <p>
                In zijn laatste jaar van de middelbare school onderzocht Boris, 
                Johan's oudste zoon de rol van 
                geografische gegevens in het werk van Johan. Voor dit onderzoek
                ordende en digitaliseerde Boris al zijn werken, wat van enorme
                waarde was bij het maken van dit boek. Het eindresultaat van 
                zijn onderzoek presenteerde Boris in boekvorm, samen met een 
                overzicht van alle werken van Johan en een ingebonden kopie van
                de Atlas van de evenaar. Hij ontving voor dit onderzoek de 
                juryprijs van zijn school voor het beste profielwerkstuk binnen
                zijn profiel.
              </p>
              <Link to="/PWS_Boris_Cornelissen" className="btn btn-secondary">
                Download het profielwerkstuk van Boris
              </Link>
          </Col>
        </Row>
      </Container>
      
      <div className="bg-light mt-3">
        <Container className="footer py-5">
          <Row>
            <Col className="py-3">
              <Link to="/book/p8" className="btn btn-secondary mb-2">
                Extra online materiaal
              </Link><br/>
              <Link to="https://stilinovi.nl/shop/de-aarde-is-onder-mijn-voeten/"
              className="btn btn-primary">
                Bestel het boek
              </Link>
            </Col>
            <Col className="py-3">
              <p>
                Voor vragen of opmerkingen kunt u contact opnemen met Johan 
                Cornelissen via <Link to="mailto:info@deaardeisondermijnvoeten.nl">
                info@deaardeisondermijnvoeten.nl</Link>

              </p>
              &copy; Johan Cornelissen 2008–{(new Date()).getFullYear()}
              <br />
              Website door <Link to="bascornelissen.nl">Bas Cornelissen</Link>
            </Col>
          </Row>
        </Container>
      </div>


      
    </>
  )
}


export const query = graphql`
  query {
    background: file(name: {eq: "background"}) {
      childImageSharp {
        fluid(maxWidth: 1700) {
          originalName
          ...GatsbyImageSharpFluid
        }
      }
    }

    book: file(name: {eq: "boek2"}) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    johan: file(name: {eq: "zelfportret"}) {
      childImageSharp {
        fluid(maxWidth: 500) {
          originalName
          ...GatsbyImageSharpFluid
        }
      }
    }

    page(page_num: {eq: "8"}) {
      page_num
      chapter {
        chapter_num
        title
      }
      objects {
        ...objectProperties
        linked_object {
          ...objectProperties
          audio: file {
            relativePath
          }
        }
        linked_series {
          title
          year
          place
          objects {
            ...objectProperties
          }
        }
        thumbnail: file {
          childImageSharp {
            fluid(maxWidth: 600) {
              presentationHeight
              presentationWidth
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`